.left-pane {
}

.right-pane {
}

.layout-pane {
  display: grid;
  grid-template-columns: 1fr 6fr;
}
